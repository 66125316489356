@font-face {
  font-family: Minecraft Regular;
  font-style: normal;
  font-weight: 400;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(/assets/fonts/MinecraftRegular.woff2) format("woff");
  font-display: swap;
}
@font-face {
  font-family: Minecraft Regular;
  font-style: normal;
  font-weight: 500;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(/assets/fonts/MinecraftBold.woff2) format("woff");
  font-display: swap;
}
@font-face {
  font-family: Minecraft Regular;
  font-style: italic;
  font-weight: 400;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(/assets/fonts/MinecraftItalic.woff2) format("woff");
  font-display: swap;
}
@font-face {
  font-family: Minecraft Regular;
  font-style: italic;
  font-weight: 500;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(/assets/fonts/MinecraftBoldItalic.woff2) format("woff");
  font-display: swap;
}
.font-Minecraft_Regular {
  font-family: "Minecraft Regular", serif;
}
