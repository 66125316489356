:root {
  --bs-body-font-size: 2em;
  --bs-body-bg: #0d0f1c;

  --bs-body-color: #fff;
  --bs-body-font-family: Sora, 'Source Sans Pro', "Font Awesome 6 Pro";
  --bs-heading-color: #ffffff;
  --bs-link-color-rgb: 255, 0, 106;

  --bs-secondary-color: #becaf4;
  --bs-secondary-bg: #2c2e58;
  --bs-secondary-rgb: 44, 46, 88;

  --bs-danger-rgb: 233, 48, 48;


  --text-muted-color: #8C8FCA;
}


/* Inputs */
input {
  border: none;
}
.form-control, .form-select {
  color: #BECAF4;
  padding: .5em .5em .5em 1em;
  background-color: #2c2e58;
  border-radius: 1.6rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(10, 10, 17, 0.3199999928), 0 0.4rem 0.8rem rgba(4, 4, 10, 0.3199999928);
  border: solid .05em #5b5d97;
  font-size: .7em;
}
.form-control::placeholder {
  color: var(--text-muted-color);
  overflow: visible;
}
.form-control:hover, .form-control:focus, .form-select:hover, .form-select:focus {
  color: #BECAF4;
  border-color: #4E3CE9;
  border-width: .1em;
  background-color: #2c2e58;
}


/* Selects */
.ng-select.select .ng-select-container {
  color: #fff;
  background: #1b1c37;
}

.ng-select.select .ng-option {
  color: #fff;
  background: #121212;
}

.ng-select.select .ng-option-disabled {
  color: #8C8FCA;
  background: #121212;
}

.ng-select.select .ng-option.ng-option-marked, .ng-select.select .ng-option.ng-option-selected.ng-option-marked {
  color: #fff;
  background: #333688;
}

.ng-select.select .ng-option.ng-option-selected {
  color: #fff;
  background: #4449b6;
}


/* Buttons */
.btn {
  --bs-btn-border-radius: .8em;
  --bs-btn-border-width: unset;
  font-size: .7em;
}
.btn-secondary, .btn-secondary:hover {
  --bs-btn-bg: #2C2E58;
  --bs-btn-hover-bg: #2C2E58;
  --bs-btn-active-bg: #4133BD;
}

/* Paginator */
.paginator {
  margin-top: 1em;
  display: flex;
  align-items: center;
}
.paginator > * {
  font-size: 1em;
  margin-left: .2em;
  padding: .6em .8em;
}
.paginator > button {
  border-radius: .6em;
}
.paginator > div {
  color: var(--text-muted-color);
}
.paginator > button:first-child {
  margin-right: 11rem;
}
.paginator > button:last-child {
  margin-left: 11rem;
}


/* Cards */
.card {
  background-color: #1b1c37;
  margin-top: 1em;

  border-radius: 2rem;
  border: solid .05em #5B5D98;
}
.card.card-1 {
  background-color: #232444;
}
.card.card-2 {
  background-color: #2C2E58;
}
.card-img-top {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}


/* Text */
a.no-line {
  text-decoration: none;
}

.text-muted {
  color: var(--text-muted-color);
}
