html {
  font-size: .6em;
}
.page > * {
  padding-left: unset;
  padding-right: unset;
}


/* Navbar */
.nav-item > * {
  margin-left: .6em;
  padding: .8em;

  font-size: 1.8rem;
  color: #becaf4;
}
.nav-item > *:not(.account, .active) {
  border: none;
  background: none;
}


/* Footer */
.page-footer {
  background-color: #1a1c37;
  border: unset;
  border-radius: unset;
}

.footer-brand > img {
  float: left;
  height: 4em;
  width: auto;
}
.footer-brand > .footer-title {
  margin-left: 2.5em;
  font-size: 2em;
}
.footer-links > p {
  font-size: 1em;
  font-weight: bold;
  color: var(--bs-body-color);
}
.footer-links > p > a {
  color: var(--bs-body-color);
  text-decoration: unset;
}


:root {
  --color: #FFFFFF;
  --shadow: #3F3F3F;
  --text-decoration: none;
}

span.preview {
  color: var(--color);
  text-shadow: 5px 5px var(--shadow);
  text-decoration: var(--text-decoration);
}
span.no-shadow > * {
  text-shadow: none!important;
}

span.preview.black {
  --color: #000000;
  --shadow: #000000;
}

span.preview.dark_blue {
  --color: #0000AA;
  --shadow: #00002A;
}

span.preview.dark_green {
  --color: #00AA00;
  --shadow: #002A00;
}

span.preview.dark_aqua {
  --color: #00AAAA;
  --shadow: #002A2A;
}

span.preview.dark_red {
  --color: #AA0000;
  --shadow: #2A0000;
}

span.preview.dark_purple {
  --color: #AA00AA;
  --shadow: #2A002A;
}

span.preview.gold {
  --color: #FFAA00;
  --shadow: #2A2A00;
}

span.preview.gray {
  --color: #AAAAAA;
  --shadow: #2A2A2A;
}

span.preview.dark_gray {
  --color: #555555;
  --shadow: #151515;
}

span.preview.blue {
  --color: #5555FF;
  --shadow: #15153F;
}

span.preview.green {
  --color: #55FF55;
  --shadow: #153F15;
}

span.preview.aqua {
  --color: #55FFFF;
  --shadow: #153F3F;
}

span.preview.red {
  --color: #FF5555;
  --shadow: #3F1515;
}

span.preview.light_purple {
  --color: #FF55FF;
  --shadow: #3F153F;
}

span.preview.yellow {
  --color: #FFFF55;
  --shadow: #3F3F15;
}

span.preview.white {
  --color: #FFFFFF;
  --shadow: #3F3F3F;
}

span.preview.bold {
  font-weight: bolder;
}
span.preview.italic {
  font-style: italic;
}

span.preview.underlined {
  --text-decoration: underline;
}
span.preview.line-through {
  --text-decoration: line-through;
}
span.preview.underlined.line-through {
  --text-decoration: underline line-through;
}


.console {
  background: #121212;
  font-family: "Minecraft Regular", serif;
}
